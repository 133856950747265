// Craete new styles, replace BS styles, extend BS components
// Tip: You can use BS mixins /node_modules/bootstrap/scss/mixins
// Tip: You can use BS utilities /node_modules/bootstrap/scss/utilities

// Alerta custom
//.alert-custom {
//  @include alert-variant(#ff4400, #ff3300, #ff2200);
//}

// create custom-bg for each theme-color 
//@each $color, $value in $theme-colors {
//  .custom-bg-#{$color} {
//    background: theme-color-level($color, -3);
//  }
//}

body {
    background: #eee;
}
.bg-mods-black {
    background: $blueMods;
}

.navbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 5px;
    color: red;
    // margin-top:7px;
}

/*
headeer top
*/
.topbar {
    background-color: $yellowMods;
    padding: 0;
}

.topbar .container .row {
    // margin:-7px;
    padding: 0;
}

.topbar .container .row .col-md-12 {
    padding: 0;
}

.topbar p {
    margin: 0;
    display: inline-block;
    font-size: 13px;
    color: $blueMods;
}

.topbar p > i {
    margin-right: 5px;
}
.topbar p:last-child {
    text-align: right;
}

header .navbar {
    margin-bottom: 0;
}

.navbar {
    li {
        color: $blueMods !important;
    }
}

.topbar li.topbar {
    display: inline;
    padding-right: 18px;
    line-height: 52px;
    transition: all 0.3s linear;
}

.topbar li.topbar:hover {
    color: $blueMods !important;
}

.topbar ul.info i {
    color: $blueMods;
    font-style: normal;
    margin-right: 8px;
    display: inline-block;
    position: relative;
    top: 4px;
}

.topbar ul.info li {
    float: right;
    padding-left: 30px;
    color: $blueMods;
    font-size: 13px;
    line-height: 44px;
}

.topbar ul.info i span {
    color: #aaa;
    font-size: 13px;
    font-weight: 400;
    line-height: 50px;
    padding-left: 18px;
}

ul.social-network {
    border: none;
    margin: 0;
    padding: 0;
}

ul.social-network li {
    border: none;
    margin: 0;

}

ul.social-network li i {
    margin: 0;
}

ul.social-network li {
    display: inline;
    margin: 0 5px;
    border: 0 solid #2D2D2D;
    padding: 5px 0 0;
    display: inline-block;
    text-align: center;
    vertical-align: baseline;
    color: $blueMods;
}

ul.social-network {
    list-style: none;
    margin: 5px 0 10px -25px;
    float: right;
}

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    transition: 0.3s ease-out;
}
a {
    color: #0a0a0a;
    text-decoration: none;
}

li {
    list-style-type: none;
}

body {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: $blueMods;
    min-width: 100% !important;
    min-height: 100% !important;
}

h3 {
    text-align: center;
    text-shadow: #B3B3B3 3px;
    font-size: 3em;
}

section {
    padding-top: 30px;
    padding-bottom: 30px;
    .container {
        box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #111;
        padding: 40px;
        background: white;
    }
}

.navbar {
    /*@media (max-width: 979px) { 
        height: auto !important;
    }
    .navbar-toggler {
        background: white;
    }*/
}

.active:after {
    content: '';
    display: block !important;
    margin: auto !important;
    height: 3px !important;
    margin-top: 0 !important;
    width: 100% !important;
    background: $yellowMods !important;
    padding-bottom: 0 !important;
}
.navbar {
    min-height: 70px !important;
    &.navbar-light {
        background: rgba(0,0,0,0);
    }
    border-radius: 0;
    transition: all 0.3s ease-out;
    &.navbar-expand-lg {
        min-height: 100px !important;
    }

    .navbar-brand img {
        padding-top: 5px;
        height: 80px;
        transition: all 0.3s ease-out;
    }

    .nav-link {
        span {
            margin: 0 3px;
            display: block;
        }
        color: $blueMods !important;

        &:active,
        &:hover {
            padding-bottom: 0;
            animation: all 2s ease-in-out;
        }
        &:after {
            content: '';
            display: block;
            margin: auto;
            height: 3px;
            margin-top: 0;
            width: 0;
            background: transparent;
            transition: width 0.2s ease, background-color 0.2s ease;
        }
        &:hover:after {
            width: 100%;
            background: $yellowMods;
        }
    }
}

.affix {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

/* fixed to top styles */

.logo-mods {
    background: url("../images/logo-blue.png") 100% 100%;
    background-size: 100% 100%;
    height: 60px;
    width: 130px;
    transition: height 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;

}
.navbar {
    background: rgba(255,255,255,0.5) !important;
}
.affix.navbar {
    background: $blueMods !important;
    border-bottom: 2px solid $yellowMods;
    min-height: 70px !important;
    margin-top: 0;
    .nav-link {
        color: white !important;
    }

    .logo-affix {
        display: block !important;
        animation: all 2s ease-in-out;
    }
    .logo-no-affix {
        display: none !important;
        animation: all 2s ease-in-out;
    }

    .logo-mods {
        background: url("../images/logo-white.png") 100% 100%;
        background-size: 100% 100%;
        height: 50px;
        width: 100px;
    }

    /*.navbar-brand img {
      padding-top:3px;      
      height: 50px !important;
      transition: height 0.25s ease-in-out;
    } */
    /*
    background: -webkit-linear-gradient(135deg, white 50%, $yellowMods 50%);
    background: -webkit-linear-gradient(right, white 50%, $yellowMods 50%);
    background: linear-gradient(135deg, white 50%, $yellowMods 50%);
    box-shadow: 0 5px 30px -4px black;
    background-color: $yellowMods;
    box-shadow: 0 5px 30px -4px black;
    height: 70px;
    transition: all 0.25s ease-in;
  .navbar-brand img {
    padding-top:3px;      
    height: 60px !important;
    transition: all 0.25s ease-in;
  }
  .nav-link {
    padding:0 !important;
    color:$blueMods !important;
    background: none !important;
        color: $blueMods !important;
        &:hover, &:active {
            padding-bottom: 0px;
            animation: all 2s ease-in-out;
        }
        &:after {
        content: '';
        display: block;
        margin: auto;
        height: 3px;
        width: 0px;
        background: transparent;
        transition: width .2s ease, background-color .2s ease;
        }
        &:hover:after {
        width: 100%;
        background: $blueMods;
        }
    } */
}
.affix.navbar .nav-item > a,
.affix.navbar .navbar-brand {
    color: #fff;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 0.5em;
    margin-left: 0.5em;
    padding: 0.1em;
}

.text-mods-yellow {
    color: $yellowMods !important;
}

.text-mods-blue {
    color: $blueMods !important;
}

.my {
    color: $yellowMods !important;
    font-weight: 400;
}
.title-mods {
    color: $blueMods;
    font-weight: 100;
}

.title-mods2 {
    color: white;
    font-weight: 100;
}

.custom-hr {
    border-bottom: 0 solid $blueMods;
    box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 $blueMods;
}

.loadingClass {
    width: 100%;
    position: fixed;
    height: 100%;
    background: $blueMods;
}

.arrowDown {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    opacity: 0.7;
    cursor: pointer !important;
}

// FIRST HOME
.home-back {
    height: 100vh;
    width: 100vw;
    //background: url('../images/background.jpg') 100% 100%;
    //background-size:100% 100%;
}

// Service BLOCK
.service-block {
    img {
        width: 100%;
        padding: 1px;
        margin: 0 auto;
        /*border-radius: 19px;*/
        overflow: hidden;
        border: solid 2px $blueMods;
        position: relative;
        -webkit-box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, .2);
        -moz-box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, .2);
        box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, .2);
        margin-bottom: 30px;
    }
    b {
        font-weight: 100;
        font-size: 1.4em;
    }
}
.ludwig {
    margin-top: 1em;
    position: relative;
    padding-left: 1em;
    border-left: 0.2em solid lighten($blueMods, 40%);
    font-family: 'Roboto', serif;
    font-weight: 100;
    font-size: 2em;
    &:after,
    &:before {
        content: '\201C';
        font-family: 'Sanchez', serif;

    }
    &:after {
        content: '\201D';
    }
}

/*Profile Card 5*/
.card-mods {
    margin-top: 20px;
    // box-shadow: 0 0.5px 0 0 $yellowMods inset, 0 1px 2px 0 $blueMods;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow,transform;
    transition-property: box-shadow,transform;
    box-shadow: 0 0 18px rgba(245,218,78,.23) !important;

    .card-img-block {
        width: 90%;
        margin: 0 auto;
        position: relative;
        top: -25px;
        background: white;
        img {
            border-radius: 0;
            // box-shadow:0 0 10px rgba(0,0,0,0.63);
            box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 $blueMods;
            background: white;
        }
    }
    .card-text {
        text-align: justify !important;
        text-align: center;
        font-weight: 100;
    }
    .card-body {
        padding-top: 0;
        min-height: 160px;
    }
    .card-title {
        text-align: center;
    }
}

.card-mods:active,
.card-mods:focus,
.card-mods:hover {
    box-shadow: 0 20px 20px -20px rgba(0,0,0,0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.05);
}
hr.fancy {
    height: 20px;
    border: 0;
    box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.5);
}

.custom-bg {
    //background: linear-gradient(15deg, white 50%, $blueMods 50%);
    background-image: linear-gradient(180deg,#f8fafc 75%, $blueMods 75%)!important;
}

.custom-bg {
    //background: linear-gradient(15deg, white 50%, $blueMods 50%);
    background-image: linear-gradient(180deg,transparent 75%, $blueMods 75%)!important;
}

.custom-bg-white {
    //background: linear-gradient(15deg, white 50%, $blueMods 50%);
    background-image: linear-gradient(180deg,$blueMods 75%, #f8fafc 75%)!important;
}

.container-module {
    // min-height: calc(100vh - 100px);
}

.debug-border {
    border: 2px solid red;
}

.list-type3 {
    margin: 0 auto;
    height: auto;
}
.list-type3 li {
    float: left;
    height: 35px;
    line-height: 35px;
    position: relative;
    font-size: 15px;
    margin-bottom: 12px;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    transition: background-color 1.5s ease;
}
.list-type3 li {
    padding: 0 60px 0 12px;
    background: #0089e0;
    color: #fff;
    text-decoration: none;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
}

.list-type3 li:before {
    content: "";
    float: left;
    position: absolute;
    top: 0;
    left: -12px;
    width: 0;
    height: 0;
    border-color: transparent #0089e0 transparent transparent;
    border-style: solid;
    border-width: 18px 12px 18px 0;
}

.list-type3 li:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    float: left;
    width: 6px;
    height: 6px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #fff;
    -moz-box-shadow: -1px -1px 2px #004977;
    -webkit-box-shadow: -1px -1px 2px #004977;
    box-shadow: -1px -1px 2px #004977;
}
.list-type3 li:hover {
    //background:#555;
}

.list-type3 li:hover:before {
    // border-color:transparent #0089e0 transparent transparent;
}

.form-control,
.form-group .form-control {
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#009688, #009688), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#009688, #009688), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
    -webkit-background-size: 0 2px, 100% 1px;
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center -webkit-calc(100% - 1px);
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.form-control::-moz-placeholder,
.form-group .form-control::-moz-placeholder {
    color: #BDBDBD;
    font-weight: 400;
}

.form-control:-ms-input-placeholder,
.form-group .form-control:-ms-input-placeholder {
    color: #BDBDBD;
    font-weight: 400;
}

.form-control::-webkit-input-placeholder,
.form-group .form-control::-webkit-input-placeholder {
    color: #BDBDBD;
    font-weight: 400;
}

.form-control[disabled],
.form-control[readonly],
.form-group .form-control[disabled],
.form-group .form-control[readonly],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
    background-color: rgba(0, 0, 0, 0);
}

.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
    background-image: none;
    border-bottom: 1px dotted #D2D2D2;
}

.form-group {
    position: relative;
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-static label.control-label {
    position: absolute;
    pointer-events: none;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
}

.form-group.label-floating label.control-label {
    will-change: left, top, contents;
}

.form-group.label-placeholder:not(.is-empty) label.control-label {
    display: none;
}

.form-group .help-block {
    position: absolute;
    display: none;
}

.form-group.is-focused .form-control {
    outline: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#009688, #009688), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#009688, #009688), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
    -webkit-background-size: 100% 2px, 100% 1px;
    background-size: 100% 2px, 100% 1px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.form-group.is-focused .form-control .material-input:after {
    background-color: #009688;
}

.form-group.is-focused label,
.form-group.is-focused label.control-label {
    color: #009688;
}

.form-group.is-focused.label-placeholder label,
.form-group.is-focused.label-placeholder label.control-label {
    color: #BDBDBD;
}

.form-group.is-focused .help-block {
    display: block;
}

.form-group.has-warning .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group.has-warning.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff5722), to(#ff5722)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#ff5722, #ff5722), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#ff5722, #ff5722), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#D2D2D2, #D2D2D2);
}

.form-group.has-warning .help-block,
.form-group.has-warning label.control-label {
    color: #ff5722;
}

.form-group.has-error .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group.has-error .help-block,
.form-group.has-error label.control-label {
    color: #f44336;
}

.form-group.has-success .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group.has-success.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4caf50), to(#4caf50)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#4caf50, #4caf50), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#4caf50, #4caf50), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2);
}

.form-group.has-success .help-block,
.form-group.has-success label.control-label {
    color: #4caf50;
}

.form-group.has-info .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group.has-info.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#03a9f4), to(#03a9f4)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#03a9f4, #03a9f4), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#03a9f4, #03a9f4), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#D2D2D2, #D2D2D2);
}

.form-group.has-info .help-block,
.form-group.has-info label.control-label {
    color: #03a9f4;
}

.form-group textarea {
    resize: none;
}

.form-group textarea~.form-control-highlight {
    margin-top: -11px;
}

.form-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-group select~.material-input:after {
    display: none;
}

.form-control {
    margin-bottom: 7px;
}

.form-control::-moz-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400;
}

.form-control:-ms-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400;
}

.form-control::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400;
}
.checkbox label,
.radio label,
label {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400;
}

label.control-label {
    font-size: 12px;
    line-height: 1.07142857;
    font-weight: 400;
    margin: 16px 0 0;
}

.help-block {
    margin-top: 0;
    font-size: 12px;
}

.form-group {
    padding-bottom: 7px;
    margin: 28px 0 0;
}

.form-group .form-control {
    margin-bottom: 7px;
}

.form-group .form-control::-moz-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group .form-control:-ms-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group .form-control::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group .checkbox label,
.form-group .radio label,
.form-group label {
    font-size: 16px;
    line-height: 1.42857143;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group label.control-label {
    font-size: 12px;
    line-height: 1.07142857;
    font-weight: 400;
    margin: 16px 0 0;
}

.form-group .help-block {
    margin-top: 0;
    font-size: 12px;
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
    top: -7px;
    font-size: 16px;
    line-height: 1.42857143;
}

.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label,
.form-group.label-static label.control-label {
    top: -30px;
    left: 0;
    font-size: 16px;
    line-height: 1.07142857;
    font-weight: 300;
}

.form-group.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -30px;
    left: 0;
    font-size: 12px;
    line-height: 1.07142857;
}

.form-group.form-group-sm {
    padding-bottom: 3px;
    margin: 21px 0 0;
}

.form-group.form-group-sm .form-control {
    margin-bottom: 3px;
}

.form-group.form-group-sm .form-control::-moz-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group.form-group-sm .form-control:-ms-input-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group.form-group-sm .form-control::-webkit-input-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group.form-group-sm .checkbox label,
.form-group.form-group-sm .radio label,
.form-group.form-group-sm label {
    font-size: 11px;
    line-height: 1.5;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group.form-group-sm label.control-label {
    font-size: 9px;
    line-height: 1.125;
    font-weight: 400;
    margin: 16px 0 0;
}

.form-group.form-group-sm .help-block {
    margin-top: 0;
    font-size: 9px;
}

.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
    top: -11px;
    font-size: 11px;
    line-height: 1.5;
}

.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-sm.label-static label.control-label {
    top: -25px;
    left: 0;
    font-size: 9px;
    line-height: 1.125;
}

.form-group.form-group-sm.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -25px;
    left: 0;
    font-size: 9px;
    line-height: 1.125;
}

.form-group.form-group-lg {
    padding-bottom: 9px;
    margin: 30px 0 0;
}

.form-group.form-group-lg .form-control {
    margin-bottom: 9px;
}

.form-group.form-group-lg .form-control::-moz-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group.form-group-lg .form-control:-ms-input-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group.form-group-lg .form-control::-webkit-input-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group.form-group-lg .checkbox label,
.form-group.form-group-lg .radio label,
.form-group.form-group-lg label {
    font-size: 18px;
    line-height: 1.3333333;
    color: #BDBDBD;
    font-weight: 400;
}

.form-group.form-group-lg label.control-label {
    font-size: 14px;
    line-height: 0.99999998;
    font-weight: 400;
    margin: 16px 0 0;
}

.form-group.form-group-lg .help-block {
    margin-top: 0;
    font-size: 14px;
}

.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
    top: -5px;
    font-size: 18px;
    line-height: 1.3333333;
}

.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-lg.label-static label.control-label {
    top: -32px;
    left: 0;
    font-size: 14px;
    line-height: 0.99999998;
}

.form-group.form-group-lg.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -32px;
    left: 0;
    font-size: 14px;
    line-height: 0.99999998;
}

select.form-control {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.form-group.is-focused select.form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #D2D2D2;
}

.form-group.is-focused select.form-control[multiple],
select.form-control[multiple] {
    height: 85px;
}

.input-group-btn .btn {
    margin: 0 0 7px;
}

.form-group.form-group-sm .input-group-btn .btn {
    margin: 0 0 3px;
}

.form-group.form-group-lg .input-group-btn .btn {
    margin: 0 0 9px;
}

.input-group .input-group-btn {
    padding: 0 12px;
}

.input-group .input-group-addon {
    border: 0;
    background: 0 0;
}

.form-group input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.contact-widget-section .single-contact-widget {
    background: #f9f9f9;
    padding: 20px 25px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
    height: 260px;
    margin-top: 25px;
    transition: all 0.3s ease-in-out;
}

.contact-widget-section .single-contact-widget i {
    font-size: 75px;
}

.contact-widget-section .single-contact-widget h3 {
    font-size: 20px;
    color: #333;
    font-weight: 700;
    padding-bottom: 10px;
}

.contact-widget-section .single-contact-widget p {
    line-height: 16px;
}

.contact-widget-section .single-contact-widget:hover {
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.46);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

#contactForm {
    margin-top: -10px;
    margin-bottom: 20px;
}

#contactForm .form-group label.control-label {
    color: #8c8c8c;
}

#contactForm .form-control {
    font-weight: 500;
    height: auto;
}


.form-group {
  .error {
    color:rgba(255,0,0,0.5);
  }
}