// Replace BS variables
// node_modules/bootstrap/scss/_variables.scss
// Copy the variables in this file

$blueMods: #162d45;
$yellowMods: #f5da4e;
$primary: $yellowMods;
$secondary: $blueMods;
$body-color: $blueMods !default;

